import AbstractStatetfulView from '../../framework/view/abstract-stateful-view.js';
import {StartButtonState} from '../../consts.js';

const createStartButtonTemplate = (state) => {
  const disabled = state.buttonState === StartButtonState.DISABLED ? 'disabled' : '';
  return `<button class="button header__start-button" id="start-button" ${disabled}>START</button>`;
};

export default class StartButtonView extends AbstractStatetfulView {
  constructor(buttonState) {
    super();
    this._state = StartButtonView.parseParamsToState(buttonState);
  }

  get template() {
    return createStartButtonTemplate(this._state);
  }

  setStartButtonClickHandler = (callback) => {
    this._callback.startButtonClick = callback;
    this.element.addEventListener('click', this.#startButtonClickHandler);
  };

  #startButtonClickHandler = (evt) => {
    evt.preventDefault();

    this._callback.startButtonClick();
  };

  _restoreHandlers() {
    this.setStartButtonClickHandler(this._callback.startButtonClick);
  }

  changeButtonState(buttonState) {
    const update = StartButtonView.parseParamsToState(buttonState);
    this.updateElement(update);
  }

  static parseParamsToState = (buttonState) => ({'buttonState': buttonState});
}
