/* eslint-disable arrow-body-style */
import AbstractView from '../../framework/view/abstract-view';

import SimpleBar from 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

const menuListTemplate = (menuListData) => {
  let popupMenu = '';

  for (const sublistTitle in menuListData) {
    popupMenu += `<li class="popup-menu__column">
      <h3 class="title title--h3 title--gradient popup-menu__title">${sublistTitle}</h3>
      <ul class="popup-menu__column-list">`;

    for( const sublistItem of menuListData[sublistTitle]) {
      const src = `./img/${sublistItem.path}/${sublistItem.filename}.${sublistItem.format}`;
      const srcset = `./img/${sublistItem.path}/${sublistItem.filename}@2x.${sublistItem.format} 2x`;
      const alt = sublistItem.name;

      popupMenu += `<li class="popup-menu__column-item"><img src="${src}" width="50" height="50" alt="${alt}" srcset="${srcset}"><a class="link link--color-black link--solid">${sublistItem.name}</a>
      </li>`;
    }

    popupMenu += `</ul>
    </li>`;
  }

  return popupMenu;
};

const selectPhotoPopupTemplate = (menuListData) => {
  return `<div class="popup-menu hidden-block" id="popup-menu">
    <h2 class="visually-hidden">Puzzle Categories</h2>
    <div class="popup-menu__wrapper container">
      <div class="popup-menu__inner grid-cards grid-cards--auto-columns">
        <ul class="popup-menu__list grid-cards__list">
          ${menuListTemplate(menuListData)}
        </ul>
      </div>
      <button class="close-button popup-menu__close-button"><span class="close-button__semi-cross close-button__semi-cross--left"></span><span class="close-button__semi-cross close-button__semi-cross--right"></span></button>
    </div>
  </div>`;
};

export default class SelectPhotoPopupView extends AbstractView {
  #menuData = null;

  constructor(menuData) {
    super();
    this.#menuData = menuData;

    // eslint-disable-next-line no-unused-vars
    const simpleBar = new SimpleBar(this.element.querySelector('.popup-menu__inner'), {
      autoHide: false,
    });
  }

  get template() {
    return selectPhotoPopupTemplate(this.#menuData);
  }

  // Item Click
  setItemClickHandler(callback) {
    this._callback.itemClick = callback;
    this.element.querySelectorAll('.popup-menu__column-item a').forEach((item) => {
      item.addEventListener('click', this.#itemClickHandler);
    });
  }

  #itemClickHandler = (evt) => {
    evt.preventDefault();
    const src = evt.target.parentElement.querySelector('img').src;
    this._callback.itemClick(src);
  };

  // Close Click
  setCloseClickHandler(callback) {
    this._callback.closeClick = callback;
    this.element.querySelector('.popup-menu__close-button').addEventListener('click', this.#closeClickHandler);
  }

  #closeClickHandler = (evt) => {
    evt.preventDefault();
    this._callback.closeClick();
  };

  showMenu = () => {
    this.element.classList.remove('hidden-block');

    setTimeout(() => {
      this.element.classList.add('popup-menu--opened');
    }, 0);
  };

  hideMenu = () => {
    this.element.classList.remove('popup-menu--opened');
    this.element.querySelector('.simplebar-content-wrapper').scrollTop = 0;

    setTimeout(() => {
      this.element.classList.add('hidden-block');
    }, 500);
  };
}
