import PuzzleMessageView from '../view/main/puzzle-message-view';

import {render} from '../framework/render.js';

export default class PuzzleMessagePresenter {
  #container = null;

  #puzzleMessageView = null;

  #puzzleMessageModel = null;

  constructor(container, puzzleMessageModel) {
    this.#container = container;
    this.#puzzleMessageModel = puzzleMessageModel;

    this.#puzzleMessageModel.addObserver(this.#modelDataChangeHandler);
  }

  init() {
    this.#puzzleMessageView = new PuzzleMessageView(this.#puzzleMessageModel.puzzleMessageState);
    render(this.#puzzleMessageView, this.#container);
  }

  #modelDataChangeHandler = () => {
    this.#puzzleMessageView.changeMessageState(this.#puzzleMessageModel.puzzleMessageState);
  };
}
