import PuzleBoxView from '../view/main/puzzle-box-view';
import EndGameModalView from '../view/main/end-game-modal-view';

import {render, remove} from '../framework/render.js';
import {createIdArray, shuffleArray, compareArrays} from '../utils';
import {PuzzleMessageState, HeaderTriggerState} from '../consts';

export default class PuzleBoxPresenter {
  #puzzleBoxWrapperElement = document.querySelector('.puzzle__box-wrapper');
  #pageWrapperElement = document.querySelector('.wrapper');

  #puzzleBoxView = null;
  #endGameModalView = null;

  #photosModel = null;
  #pieceCountModel = null;
  #puzzleMessageModel = null;
  #headerTriggerModel = null;

  #img = null;
  #imgAsRatio = null;

  #wrapperPaddingTop = null;
  #wrapperPaddingBottom = null;
  #wrapperWidth = null;
  #wrapperHeight = null;

  #rowCount = null;
  #colCount = null;

  #itemIdArray = null;
  #shuffledIdArray = null;

  #moves = null;

  constructor(photosModel, pieceCountModel, puzzleMessageModel, headerTriggerModel) {
    this.#photosModel = photosModel;
    this.#pieceCountModel = pieceCountModel;
    this.#puzzleMessageModel = puzzleMessageModel;
    this.#headerTriggerModel = headerTriggerModel;
  }

  init() {
    if(this.#puzzleBoxView) {
      remove(this.#puzzleBoxView);
    }

    this.#moves = 0;

    this.#rowCount = +this.#pieceCountModel.pieceCount.split('_')[0];
    this.#colCount = +this.#pieceCountModel.pieceCount.split('_')[1];

    this.#itemIdArray = createIdArray(this.#rowCount * this.#colCount);
    this.#shuffledIdArray = shuffleArray(this.#itemIdArray);

    this.#img = this.#photosModel.bigPhoto;
    this.#imgAsRatio = this.#img.naturalWidth / this.#img.naturalHeight;

    this.#calculateWrapperSizes();

    this.#renderPuzzleBox();

    this.#renderModal();

    window.addEventListener('resize', this.#windowResizeHandler);
    this.#headerTriggerModel.addObserver(this.#triggerStateChangeHandler);
  }

  #windowResizeHandler = () => {
    this.#calculateWrapperSizes();
    this.#puzzleBoxView.resizeBox(this.#wrapperWidth, this.#wrapperHeight, this.#imgAsRatio);
    this.#puzzleBoxView.resizeCells();
  };

  #calculateWrapperSizes() {
    this.#wrapperPaddingTop = window.getComputedStyle(this.#puzzleBoxWrapperElement).paddingTop.replace(/px/, '');
    this.#wrapperPaddingBottom = window.getComputedStyle(this.#puzzleBoxWrapperElement).paddingBottom.replace(/px/, '');
    this.#wrapperWidth = this.#puzzleBoxWrapperElement.clientWidth;
    this.#wrapperHeight = this.#puzzleBoxWrapperElement.clientHeight - this.#wrapperPaddingTop - this.#wrapperPaddingBottom;
  }

  #renderPuzzleBox() {
    this.#puzzleBoxView = new PuzleBoxView();
    this.#puzzleBoxView.resizeBox(this.#wrapperWidth, this.#wrapperHeight, this.#imgAsRatio);
    render(this.#puzzleBoxView, this.#puzzleBoxWrapperElement);
    this.#puzzleBoxView.createCells(this.#rowCount, this.#colCount, this.#img.src, this.#itemIdArray);
    this.#puzzleBoxView.shuffleItems(this.#shuffledIdArray);

    this.#puzzleBoxView.setItemDropHandler(this.#itemDropHandler);
  }

  #itemDropHandler = (currentIdArray) => {
    this.#moves++;

    if (compareArrays(currentIdArray, this.#itemIdArray)) {

      this.#endGameModalView.showModal(this.#moves);
    }
  };

  #renderModal() {
    this.#endGameModalView = new EndGameModalView();
    this.#endGameModalView.setCloseButtonClickHandler(this.#modalCloseClickHandler);
    render(this.#endGameModalView, this.#pageWrapperElement);
  }

  #modalCloseClickHandler = () => {
    this.#newGame();
  };

  #newGame() {
    remove(this.#endGameModalView);

    window.removeEventListener('resize', this.#windowResizeHandler);
    remove(this.#puzzleBoxView);
    this.#puzzleMessageModel.setPuzzleMessageState(PuzzleMessageState.VISIBLE);
    this.#headerTriggerModel.setHeaderTriggerState(HeaderTriggerState.HIDDEN);
  }

  #triggerStateChangeHandler = (updateType, triggerState) => {
    if (triggerState === HeaderTriggerState.HEADER_VISIBLE || triggerState === HeaderTriggerState.HEADER_HIDDEN) {
      this.#calculateWrapperSizes();
      this.#puzzleBoxView.resizeBox(this.#wrapperWidth, this.#wrapperHeight, this.#imgAsRatio);
      this.#puzzleBoxView.resizeCells();
    }
  };
}
