import UploadFileView from '../view/header/upload-file-view';

import {render} from '../framework/render.js';
import {UpdateType} from '../consts';

const FILE_TYPES = ['gif', 'jpg', 'jpeg', 'png'];

export default class UploadFilePresenter {
  #container = null;

  #uploadFileView = new UploadFileView();

  #photosModel = null;

  constructor(container, photosModel) {
    this.#container = container;
    this.#photosModel = photosModel;
  }

  init() {
    render(this.#uploadFileView, this.#container);

    this.#uploadFileView.setUploadFileChangeHandler(this.#uploadFileChangeHandler);
  }

  #uploadFileChangeHandler = (file) => {
    const fileName = file.name.toLowerCase();

    const isImage = FILE_TYPES.some((fileType) => fileName.endsWith(fileType));

    if (isImage) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.addEventListener('load', () => {
        const currentPhoto = {
          minSrc: reader.result,
          bigSrc: reader.result,
        };

        this.#photosModel.setCurrentPhoto(currentPhoto, UpdateType.CHANGE_PHOTO);
      });

    } else {
      const currentPhoto = {
        minSrc: null,
        bigSrc: null,
      };

      this.#photosModel.setCurrentPhoto(currentPhoto, UpdateType.CHANGE_PHOTO);
    }
  };
}
