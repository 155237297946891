import AbstractView from '../../framework/view/abstract-view.js';

const createHeaderMenuLeftTemplate = () => '<div class="header__menu-left"></div>';

export default class HeaderMenuLeftView extends AbstractView {
  get template() {
    return createHeaderMenuLeftTemplate();
  }
}


