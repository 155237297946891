import SelectPieceCountView from '../view/header/select-piece-count-view';

import {render} from '../framework/render.js';

export default class SelectPieceCountPresenter {
  #container = null;

  #selectPieceCountView = null;

  #pieceCountModel = null;

  constructor(container, pieceCountModel) {
    this.#container = container;
    this.#pieceCountModel = pieceCountModel;
  }

  init() {
    this.#selectPieceCountView = new SelectPieceCountView(this.#pieceCountModel.pieceCount);
    render(this.#selectPieceCountView, this.#container);

    this.#selectPieceCountView.setSelectPieceCountChangeHandler(this.#selectPieceCountChangeHandler);
  }

  #selectPieceCountChangeHandler = (val) => {
    this.#pieceCountModel.setCurrentPieceCount(val);
  };
}
