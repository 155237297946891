/* eslint-disable arrow-body-style */
import AbstractView from '../../framework/view/abstract-view.js';

const createUploadFileTemplate = () => {
  return (
    `<div class="upload-file-wrapper header__upload-file" id="upload-file" data-text="Upload your photo">
      <input class="upload-file" type="file" accept=".jpg, .jpeg, .png">
    </div>`
  );
};

export default class UploadFileView extends AbstractView {
  get template() {
    return createUploadFileTemplate();
  }

  setUploadFileChangeHandler = (callback) => {
    this._callback.uploadFileChange = callback;
    this.element.querySelector('input').addEventListener('change', this.#uploadFileChangeHandler);  };

  #uploadFileChangeHandler = (evt) => {
    evt.preventDefault();
    this._callback.uploadFileChange(evt.target.files[0]);
    evt.target.value = '';
  };
}
