const UserAction = {
  UPDATE_CARD: 'UPDATE_CARD',
  ADD_CARD: 'ADD_CARD',
  DELETE_CARD: 'DELETE_CARD',
  ADD_COMMENT: 'ADD_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
};

const UpdateType = {
  CHANGE_PHOTO: 'CHANGE_PHOTO',
  CHANGE_SELECT_PHOTO_BUTTON: 'CHANGE_SELECT_PHOTO_BUTTON',
};

const MenuButtonState = {
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
};

const StartButtonState = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const PuzzleMessageState = {
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
};

const PieceCountParam = {
  '3_3': '3_3',
  '4_4': '4_4',
  '5_5': '5_5',
  '6_6': '6_6',
  '7_7': '7_7',
};

const PieceCount = {
  [PieceCountParam['3_3']]: '3 x 3',
  [PieceCountParam['4_4']]: '4 x 4',
  [PieceCountParam['5_5']]: '5 x 5',
  [PieceCountParam['6_6']]: '6 x 6',
  [PieceCountParam['7_7']]: '7 x 7',
};

const HeaderTriggerState = {
  HEADER_HIDDEN: 'HEADER HIDDEN',
  HEADER_VISIBLE: 'HEADER_VISIBLE',
  HIDDEN: 'HIDDEN',
};

export { UserAction, UpdateType, MenuButtonState, PieceCount, PieceCountParam, StartButtonState, PuzzleMessageState, HeaderTriggerState};
