import AbstractView from '../../framework/view/abstract-view.js';
import {PuzzleMessageState} from '../../consts';


const createPuzzleMessageTemplate = (messageState) => {
  const messageStateClass = (messageState === PuzzleMessageState.HIDDEN)? 'puzzle-message--hidden' : '';

  return `<div class="puzzle__message ${messageStateClass}" id="puzzle-message"><span>Select a photo and press the <b>Start</b> button</span></div>`;
};

export default class PuzzleMessageView extends AbstractView {
  #messageState = null;

  constructor (messageState) {
    super();

    this.#messageState = messageState;
  }

  get template() {
    return createPuzzleMessageTemplate(this.#messageState);
  }

  changeMessageState = (messageState) => {
    if (messageState === PuzzleMessageState.HIDDEN) {
      this.element.classList.add('puzzle__message--hidden');
    } else {
      this.element.classList.remove('puzzle__message--hidden');
    }
  };
}
