import HeaderTriggerView from '../view/header/header-trigger-view';

import {render} from '../framework/render.js';
import {HeaderTriggerState} from '../consts';

export default class HeaderTriggePresenter {
  #container = null;

  #headerTriggerView = new HeaderTriggerView();

  #headerTriggerModel = null;

  constructor(container, headerTriggerModel) {
    this.#container = container;
    this.#headerTriggerModel = headerTriggerModel;
  }

  init() {
    render(this.#headerTriggerView, this.#container);

    this.#headerTriggerView.setHeaderTriggerClickHandler(this.#headerTriggerClickHandler);
  }

  #headerTriggerClickHandler = () => {
    if (this.#headerTriggerModel.triggerState === HeaderTriggerState.HEADER_VISIBLE) {
      this.#headerTriggerModel.setHeaderTriggerState(HeaderTriggerState.HEADER_HIDDEN);
    } else {
      this.#headerTriggerModel.setHeaderTriggerState(HeaderTriggerState.HEADER_VISIBLE);
    }
  };
}
