import HeaderMenuPresenter from './presenter/header-menu-presenter';
import PhotosModel from './model/photos-model';
import PieceCountModel from './model/piece-count-model';

const headerElement = document.querySelector('.header');

const photosModel = new PhotosModel();
const pieceCountModel = new PieceCountModel();

const headerMenuPresenter = new HeaderMenuPresenter(headerElement, photosModel, pieceCountModel);

headerMenuPresenter.init();

