import HeaderMenuView from '../view/header/header-menu-view';
import HeaderMenuLeftView from '../view/header/header-menu-left-view';
import HeaderMenuRightView from '../view/header/header-menu-right-view';

import UploadFilePresenter from './upload-file-presenter';
import SelectPhotoButtonPresenter from './select-photo-button-presenter';
import PhotoPreviewPresenter from './photo-preview-presenter';
import SelectPieceCountPresenter from './select-piece-count-presenter';
import StartButtonPresenter from './start-button-presenter';
import PuzzleMessagePresenter from './puzzle-message-presenter';
import HeaderTriggePresenter from './header-trigger-presenter';


import SelectPhotoButtonModel from '../model/select-photo-button-model';
import StartButtonModel from '../model/start-button-model';
import PuzzleMessageModel from '../model/puzzle-message-model';
import HeaderTriggerModel from '../model/header-trigger-model';

import {render} from '../framework/render.js';

import {HeaderTriggerState} from '../consts';

export default class HeaderMenuPresenter {
  #container = null;
  #headerElement = document.querySelector('.header');

  #puzzleContainerElement = document.querySelector('.puzzle');

  #headerMenuView = new HeaderMenuView();
  #headerMenuLeftView = new HeaderMenuLeftView();
  #headerMenuRightView = new HeaderMenuRightView();

  #photosModel = null;
  #pieceCountModel = null;
  #selectPhotoButtonModel = new SelectPhotoButtonModel;
  #startButtonModel = null;
  #puzzleMessageModel = new PuzzleMessageModel();
  #headerTriggerModel = new HeaderTriggerModel();

  #uploadFilePresenter = null;
  #selectPhotoButtonPresenter = null;
  #photoPreviewPresenter = null;
  #selectPieceCountPresenter = null;
  #startButtonPresenter = null;
  #puzzleMessagePresenter = null;
  #headerTriggePresenter = null;

  constructor(container, photosModel, pieceCountModel) {
    this.#container = container;
    this.#photosModel = photosModel;
    this.#pieceCountModel = pieceCountModel;
    this.#startButtonModel = new StartButtonModel(this.#photosModel.minPhoto, this.#selectPhotoButtonModel.currentButtonState);
  }

  init() {
    render(this.#headerMenuView, this.#container);
    render(this.#headerMenuLeftView, this.#headerMenuView.element);
    render(this.#headerMenuRightView, this.#headerMenuView.element);

    this.#renderUploadFile();
    this.#renderSelectPhotoButton();
    this.#renderPhotoPreview();
    this.#renderSelectPieceCount();
    this.#renderStartButton();
    this.#renderPuzzleMessage();
    this.#renderHeaderTrigger();

    this.#headerTriggerModel.addObserver(this.#triggerStateChangeHandler);
  }

  #renderUploadFile() {
    this.#uploadFilePresenter = new UploadFilePresenter(this.#headerMenuLeftView.element, this.#photosModel);
    this.#uploadFilePresenter.init();
  }

  #renderSelectPhotoButton() {
    this.#selectPhotoButtonPresenter = new SelectPhotoButtonPresenter(this.#headerMenuLeftView.element, this.#photosModel, this.#selectPhotoButtonModel);
    this.#selectPhotoButtonPresenter.init();
  }

  #renderPhotoPreview() {
    this.#photoPreviewPresenter = new PhotoPreviewPresenter(this.#headerMenuLeftView.element, this.#photosModel);
    this.#photoPreviewPresenter.init();
  }

  #renderSelectPieceCount() {
    this.#selectPieceCountPresenter = new SelectPieceCountPresenter(this.#headerMenuRightView.element, this.#pieceCountModel);
    this.#selectPieceCountPresenter.init();
  }

  #renderStartButton() {
    this.#startButtonPresenter = new StartButtonPresenter(this.#headerMenuRightView.element, this.#startButtonModel, this.#photosModel, this.#selectPhotoButtonModel, this.#pieceCountModel, this.#puzzleMessageModel, this.#headerTriggerModel);
    this.#startButtonPresenter.init();
  }

  #renderPuzzleMessage() {
    this.#puzzleMessagePresenter = new PuzzleMessagePresenter(this.#puzzleContainerElement, this.#puzzleMessageModel);
    this.#puzzleMessagePresenter.init();
  }

  #renderHeaderTrigger() {
    this.#headerTriggePresenter = new HeaderTriggePresenter(this.#headerElement, this.#headerTriggerModel);
    this.#headerTriggePresenter.init();
  }

  // Model handlers
  #triggerStateChangeHandler = (updateType, triggerState) => {
    switch(triggerState) {
      case HeaderTriggerState.HIDDEN:
        this.#headerElement.classList.remove('header--hidden');
        this.#headerElement.classList.remove('header--paused');
        break;
      case HeaderTriggerState.HEADER_VISIBLE:
        this.#headerElement.classList.remove('header--hidden');
        this.#headerElement.classList.add('header--paused');
        break;
      case HeaderTriggerState.HEADER_HIDDEN:
        this.#headerElement.classList.add('header--hidden');
        this.#headerElement.classList.remove('header--paused');
        break;
    }
  };
}
