/**
 * Генерирует массив из указанного количества рандомных id
 * @param {number} count - количество рандомных id
 * @returns {string[]} idArr - возвращает массив с id
 */
function createIdArray(count) {
  let curr = 0;
  const idArr = [];

  while (curr < count) {
    const id = generateId();

    // @ts-ignore
    if (!idArr.includes(id)) {
      idArr.push(id);
      curr++;
    }
  }

  return idArr;
}


/**
 * Возвращает id из 4 знаков
 */
function generateId() {
  const symbols = ['a', 'b', 'c', 'e', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  let id = '';

  for (let i = 0; i < 4; i++) {
    const index = randomInteger(0, symbols.length - 1);
    id += symbols[index];
  }

  return id;
}


/**
 * Генирирует случайное число от мин до макс включая
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
function randomInteger(min, max) {
  // случайное число от min до (max+1)
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}


/**
 * Перемешивает элементы массива
 * @param {string[]} arr
 * @return {string[]} - возвращает новый массив с перемешенными элементами
 */
function shuffleArray(arr) {

  const array = arr.slice(0);

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}


/**
 * Сравнивает два массива
 * @param {[]} arr1
 * @param {[]} arr2
 * @returns - true или false
 */

function compareArrays(arr1, arr2) {
  const str1 = arr1.join('-');
  const str2 = arr2.join('-');

  return str1 === str2;
}


export {compareArrays, shuffleArray, randomInteger, generateId, createIdArray};
