/* eslint-disable arrow-body-style */
import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.css';
import SimpleBar from 'simplebar';

import AbstractView from '../../framework/view/abstract-view';
import {PieceCount} from '../../consts';

const createSelectPieceCountTemplate = (pieceCount) => {
  let template = '';

  template += `<div class="custom-select custom-select--green-white header__select-piece-count">
      <select class="custom-select__field js-custom-select" id="select-piece-count">`;

  for(const option in PieceCount) {
    const selected = pieceCount === option? 'selected' : '';
    template += `<option ${selected} value="${option}">${PieceCount[option]}</option>`;
  }

  template += `</select>
    </div>`;

  return template;
};

export default class SelectPieceCountView extends AbstractView {
  #pieceCount = null;
  #choice = null;
  #selectFieldElement = null;
  #scrollBox = null;
  #simpleBar = null;

  constructor (pieceCount) {
    super();

    this.#pieceCount = pieceCount;

    this.#selectFieldElement = this.element.querySelector('select');

    this.#choice = new Choices(this.#selectFieldElement, {
      searchEnabled: false,
      itemSelectText: '',
      maxItemCount: 3,
    });

    this.#scrollBox = this.#selectFieldElement.parentElement.nextElementSibling.querySelector('.choices__list');

    this.#simpleBar = new SimpleBar(this.#scrollBox, {
      autoHide: true,
    });
  }

  get template() {
    return createSelectPieceCountTemplate(this.#pieceCount);
  }

  setSelectPieceCountChangeHandler = (callback) => {
    this._callback.selectPieceCountChange = callback;
    this.#selectFieldElement.addEventListener('change', this.#selectPieceCountChangeHandler);
  };

  #selectPieceCountChangeHandler = (evt) => {
    evt.preventDefault();

    this.#simpleBar.unMount();
    this.#simpleBar = new SimpleBar(this.#scrollBox, {
      autoHide: true,
    });

    this._callback.selectPieceCountChange(evt.target.value);
  };
}
