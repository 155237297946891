import Observable from '../framework/observable.js';
import {MenuButtonState, StartButtonState,  UpdateType} from '../consts';

export default class StartButtonModel extends Observable {
  #currentButtonState = null;

  constructor(currentPhotoSrc, selectPhotoButtonState) {
    super();
    this.#currentButtonState = (currentPhotoSrc && selectPhotoButtonState === MenuButtonState.CLOSED)? StartButtonState.ENABLED : StartButtonState.DISABLED;
  }

  get currentButtonState() {
    return this.#currentButtonState;
  }

  checkButtonState = (currentPhotoSrc, selectPhotoButtonState) => {
    this.#currentButtonState = (currentPhotoSrc && selectPhotoButtonState === MenuButtonState.CLOSED)? StartButtonState.ENABLED : StartButtonState.DISABLED;
  };

  toggleCurrentButtonState = () => {
    if (this.#currentButtonState === StartButtonState.DISABLED) {
      this.#currentButtonState = StartButtonState.ENABLED;
    } else {
      this.#currentButtonState = StartButtonState.DISABLED;
    }

    this._notify(UpdateType.CHANGE_SELECT_PHOTO_BUTTON, this.#currentButtonState);
  };

  setCurrentButtonState = (buttonState, updateType) => {
    this.#currentButtonState = buttonState;

    this._notify(updateType, this.#currentButtonState);
  };
}
