import AbstractView from '../../framework/view/abstract-view.js';

const createHeaderMenuTemplate = () => '<div class="header__menu"></div>';

export default class HeaderMenuView extends AbstractView {
  get template() {
    return createHeaderMenuTemplate();
  }
}


