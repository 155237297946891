import Observable from '../framework/observable.js';
import {PuzzleMessageState} from '../consts';

export default class PuzzleMessageModel extends Observable {
  #puzzleMessageState= null;

  constructor() {
    super();
    this.#puzzleMessageState = PuzzleMessageState.VISIBLE;
  }

  get puzzleMessageState() {
    return this.#puzzleMessageState;
  }

  setPuzzleMessageState = (puzzleMessageState, updateType) => {
    this.#puzzleMessageState = puzzleMessageState;

    this._notify(updateType, this.#puzzleMessageState);
  };
}
