import SelectPhotoPopupView from '../view/header/select-photo-popup-view';

import {MenuButtonState} from '../consts';

import {render, RenderPosition} from '../framework/render.js';

export default class SelectPhotoPopupPresenter {
  #headerElement = document.querySelector('.header');
  #mainElement = document.querySelector('main');

  #selectPhotoPopupView = null;

  #photosModel = null;
  #selectPhotoButtonModel = null;

  constructor(photosModel, selectPhotoButtonModel) {
    this.#photosModel = photosModel;
    this.#selectPhotoButtonModel = selectPhotoButtonModel;

    this.#selectPhotoButtonModel.addObserver(this.#modelDataChangeHandler);
  }

  init() {
    this.#selectPhotoPopupView = new SelectPhotoPopupView(this.#photosModel.photosMenuData);
    render(this.#selectPhotoPopupView, this.#headerElement, RenderPosition.AFTEREND);
    this.#selectPhotoPopupView.setItemClickHandler(this.#popupItemClickHandler);
    this.#selectPhotoPopupView.setCloseClickHandler(this.#popupCloseClickHandler);
  }


  #modelDataChangeHandler = (updateType, menuButtonState) => {
    if (menuButtonState === MenuButtonState.OPENED) {
      this.#selectPhotoPopupView.showMenu();
      this.#mainElement.classList.add('hidden-block');
    } else {
      this.#selectPhotoPopupView.hideMenu();

      setTimeout(() => {
        this.#mainElement.classList.remove('hidden-block');
      }, 500);
    }
  };

  #popupItemClickHandler = (src) => {
    this.#selectPhotoButtonModel.toggleCurrentButtonState();
    const bigSrc = src.replace(/\/mini/, '');
    this.#photosModel.setCurrentPhoto({minSrc: src, bigSrc: bigSrc});
  };

  #popupCloseClickHandler = () => {
    this.#selectPhotoButtonModel.toggleCurrentButtonState();
  };
}
