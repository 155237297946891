/* eslint-disable arrow-body-style */
import AbstractStatetfulView from '../../framework/view/abstract-stateful-view.js';

const createPhotoPreviewTemplate = (state) => {
  const img = state.img? '' : '<img width="80" height="80" alt="">';

  return (
    `<div class="image-preview header__image-preview">
      <div class="image-preview__blank"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
      </div>
      ${img}
    </div>`);
};

export default class PhotoPreviewView extends AbstractStatetfulView {
  constructor(img) {
    super();
    this._state = PhotoPreviewView.parseParamsToState(img);

    if (img) {
      this.element.appendChild(img);
    }
  }

  get template() {
    return createPhotoPreviewTemplate(this._state);
  }

  _restoreHandlers() {

  }

  changePreviewSrc(img) {
    const update = PhotoPreviewView.parseParamsToState(img);
    this.updateElement(update);


    if (img) {
      this.element.appendChild(img);
      setTimeout(() => {
        const blank = document.querySelector('.image-preview__blank');
        blank.classList.add('image-preview__blank--invisible');
      }, 100);

    }
  }

  static parseParamsToState = (img) => ({'img': img});
}
