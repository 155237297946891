import StartButtonView from '../view/header/start-button-view';

import PuzleBoxPresenter from './puzzle-box-presenter';

import {PuzzleMessageState, HeaderTriggerState} from '../consts';

import {render} from '../framework/render.js';

export default class StartButtonPresenter {
  #container = null;

  #startButtonView = null;

  #puzleBoxPresenter = null;

  #startButtonModel = null;
  #photosModel = null;
  #pieceCountModel = null;
  #selectPhotoButtonModel = null;
  #puzzleMessageModel = null;
  #headerTriggerModel = null;

  constructor(container, startButtonModel, photosModel, selectPhotoButtonModel, pieceCountModel, puzzleMessageModel, headerTriggerModel) {
    this.#container = container;

    this.#startButtonModel = startButtonModel;
    this.#photosModel = photosModel;
    this.#selectPhotoButtonModel = selectPhotoButtonModel;
    this.#pieceCountModel = pieceCountModel;
    this.#puzzleMessageModel = puzzleMessageModel;
    this.#headerTriggerModel = headerTriggerModel;

    this.#photosModel.addObserver(this.#modelChangeHandler);
    this.#selectPhotoButtonModel.addObserver(this.#modelChangeHandler);
  }

  init() {
    this.#startButtonView = new StartButtonView(this.#startButtonModel.currentButtonState);
    render(this.#startButtonView, this.#container);
    this.#startButtonView.setStartButtonClickHandler(this.#startButtonClickHandler);

    this.#puzleBoxPresenter = new PuzleBoxPresenter(this.#photosModel, this.#pieceCountModel, this.#puzzleMessageModel, this.#headerTriggerModel);
  }


  #modelChangeHandler = () => {
    const prevButtonState = this.#startButtonModel.currentButtonState;
    this.#startButtonModel.checkButtonState(this.#photosModel.miniPhoto, this.#selectPhotoButtonModel.currentButtonState);

    if(prevButtonState !== this.#startButtonModel.currentButtonState) {
      this.#startButtonView.changeButtonState(this.#startButtonModel.currentButtonState);
    }
  };

  #startButtonClickHandler = () => {
    this.#puzzleMessageModel.setPuzzleMessageState(PuzzleMessageState.HIDDEN);
    this.#headerTriggerModel.setHeaderTriggerState(HeaderTriggerState.HEADER_HIDDEN);
    this.#renderPuzzleBox();
  };

  #renderPuzzleBox() {
    this.#puzleBoxPresenter.init();
  }
}
