import Observable from '../framework/observable.js';
import {MenuButtonState, UpdateType} from '../consts';

export default class SelectPhotoButtonModel extends Observable {
  #currentButtonState = null;

  constructor() {
    super();
    this.#currentButtonState = MenuButtonState.CLOSED;
  }

  get currentButtonState() {
    return this.#currentButtonState;
  }

  toggleCurrentButtonState = () => {
    if (this.#currentButtonState === MenuButtonState.CLOSED) {
      this.#currentButtonState = MenuButtonState.OPENED;
    } else {
      this.#currentButtonState = MenuButtonState.CLOSED;
    }

    this._notify(UpdateType.CHANGE_SELECT_PHOTO_BUTTON, this.#currentButtonState);
  };

  setCurrentButtonState = (buttonState, updateType) => {
    this.#currentButtonState = buttonState;

    this._notify(updateType, this.#currentButtonState);
  };
}
