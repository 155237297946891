import AbstractView from '../../framework/view/abstract-view.js';

const createHeaderMenuRightTemplate = () => '<div class="header__menu-right"></div>';

export default class HeaderMenuRightView extends AbstractView {
  get template() {
    return createHeaderMenuRightTemplate();
  }
}


