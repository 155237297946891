import PhotoPreviewView from '../view/header/photo-preview-view';

import {render, RenderPosition} from '../framework/render.js';

export default class PhotoPreviewPresenter {
  #container = null;

  #photoPreviewView = null;
  #photosModel = null;

  constructor(container, photosModel) {
    this.#container = container;
    this.#photosModel = photosModel;

    this.#photosModel.addObserver(this.#modelDataChangeHandler);
  }

  init() {
    const currentPhoto = this.#photosModel.miniPhoto;
    this.#photoPreviewView = new PhotoPreviewView(currentPhoto);
    render(this.#photoPreviewView, this.#container, RenderPosition.AFTEREND);
  }

  #modelDataChangeHandler = () => {
    this.#photoPreviewView.changePreviewSrc(this.#photosModel.miniPhoto);
  };
}
