import AbstractView from '../../framework/view/abstract-view.js';

const createEndGameModalTemplate = () => (
  `<div class="end-game-modal modal">
    <div class="modal__inner">
      <h2 class="title title--h2 end-game-modal__title">Congratulations !!!</h2>
      <div class="modal__inner-content end-game-modal__content">
        <p class="end-game-modal__content-result">Your result is: <span></span></p>
      </div>
      <div class="modal__inner-footer">
        <button class="modal__inner-button">Close</button>
      </div>
    </div>
  </div>`
);

export default class EndGameModalView extends AbstractView {
  #moveCount = null;

  constructor (moveCount) {
    super();

    this.#moveCount = moveCount;
  }

  setCloseButtonClickHandler = (callback) => {
    this._callback.closeButtonClick = callback;
    this.element.querySelector('.modal__inner-button').addEventListener('click', this.#closeButtonClickHandler);
  };

  #closeButtonClickHandler = (evt) => {
    evt.preventDefault();

    this._callback.closeButtonClick();
  };

  get template() {
    return createEndGameModalTemplate(this.#moveCount);
  }

  showModal = (moves) => {
    this.element.querySelector('.end-game-modal__content-result span').innerHTML = `${moves} move(s)`;
    this.element.classList.add('modal--active');
  };
}
