/* eslint-disable arrow-body-style */
import AbstractView from '../../framework/view/abstract-view.js';

const createHeaderTriggerTemplate = () => {
  return (
    '<button class="header__trigger"></button>'
  );
};

export default class HeaderTriggerView extends AbstractView {
  get template() {
    return createHeaderTriggerTemplate();
  }

  setHeaderTriggerClickHandler = (callback) => {
    this._callback.headerTriggerClick = callback;
    this.element.addEventListener('click', this.#headerTriggerClickHandler);  };

  #headerTriggerClickHandler = (evt) => {
    evt.preventDefault();
    this._callback.headerTriggerClick();

  };
}
