import Observable from '../framework/observable.js';
import {HeaderTriggerState} from '../consts';

export default class HeaderTriggerModel extends Observable {
  #triggerState = null;

  constructor() {
    super();
    this.#triggerState = HeaderTriggerState.HIDDEN;
  }

  get triggerState() {
    return this.#triggerState;
  }

  setHeaderTriggerState = (triggerState, updateType) => {
    this.#triggerState = triggerState;

    this._notify(updateType, this.#triggerState);
  };
}
