import SelectPhotoButtonView from '../view/header/select-photo-button-view';
import SelectPhotoPopupPresenter from './select-photo-popup-presenter';

import {render} from '../framework/render.js';

export default class SelectPhotoButtonPresenter {
  #container = null;

  #selectPhotoButtonView = null;
  #selectPhotoPopupPresenter = null;

  #photosModel = null;
  #selectPhotoButtonModel = null;

  constructor(container, photosModel, selectPhotoButtonModel) {
    this.#container = container;
    this.#photosModel = photosModel;
    this.#selectPhotoButtonModel = selectPhotoButtonModel;

    this.#selectPhotoButtonModel.addObserver(this.#modelDataChangeHandler);
  }

  init() {
    this.#renderSelectPhotoButton();
    this.#renderSelectPhotoPopup();

    this.#selectPhotoButtonView.setSelectPhotoButtonClickHandler(this.#selectPhotoButtonClickHandler);
  }

  #renderSelectPhotoButton() {
    this.#selectPhotoButtonView = new SelectPhotoButtonView();
    render(this.#selectPhotoButtonView, this.#container);
  }

  #renderSelectPhotoPopup() {
    this.#selectPhotoPopupPresenter = new SelectPhotoPopupPresenter(this.#photosModel, this.#selectPhotoButtonModel);
    this.#selectPhotoPopupPresenter.init();
  }

  #selectPhotoButtonClickHandler = () => {

    this.#selectPhotoButtonModel.toggleCurrentButtonState();
  };

  #modelDataChangeHandler = (updateType, buttonState) => {
    this.#selectPhotoButtonView.changeButtonState(buttonState);
  };
}
