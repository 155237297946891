import Observable from '../framework/observable.js';
import {menuListData} from '../mock/photos';

export default class PhotosModel extends Observable {
  #currentPhoto = {
    miniPhoto: null,
    bigPhoto: null,
  };

  #photosMenuData = null;

  constructor () {
    super();

    this.#photosMenuData = menuListData;
  }

  get miniPhoto() {
    return this.#currentPhoto.miniPhoto;
  }

  get bigPhoto() {
    return this.#currentPhoto.bigPhoto;
  }

  get photosMenuData() {
    return this.#photosMenuData;
  }

  setCurrentPhoto = (currentPhotoSrc, updateType) => {
    this.#currentPhoto.miniPhoto = null;
    this.#currentPhoto.bigPhoto = null;
    this._notify(updateType, this.#currentPhoto);

    if (!currentPhotoSrc.bigSrc || !currentPhotoSrc.minSrc) {
      return;
    }

    const miniPhotoPromise = new Promise((resolve) => {
      this.#currentPhoto.miniPhoto = new Image();
      this.#currentPhoto.miniPhoto.src  = currentPhotoSrc.minSrc;

      this.#currentPhoto.miniPhoto.onload = () => {
        resolve();
      };
    });

    const bigPhotoPromise = new Promise((resolve) => {
      this.#currentPhoto.bigPhoto = new Image();
      this.#currentPhoto.bigPhoto.src  = currentPhotoSrc.bigSrc;

      this.#currentPhoto.bigPhoto.onload = () => {
        resolve();
      };
    });

    Promise.all([miniPhotoPromise, bigPhotoPromise])
      .then(() => {
        this._notify(updateType, this.#currentPhoto);
      })
      .catch(() => {
        this.#currentPhoto.miniPhoto = null;
        this.#currentPhoto.bigPhoto = null;
      });
  };
}
