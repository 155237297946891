import AbstractView from '../../framework/view/abstract-view.js';
import {MenuButtonState} from '../../consts';

const selectPhotoButtonTemplate = (buttonState) => {
  const buttonStateClass = buttonState === MenuButtonState.OPENED ? 'menu-button--opened' : '';

  return `<button class="menu-button header__menu-button ${buttonStateClass}" id="menu-button">Select photo</button>`;
};

export default class SelectPhotoButtonView extends AbstractView {
  #buttonState = null;
  constructor(buttonState) {
    super();
    this.#buttonState = buttonState;
  }

  get template() {
    return selectPhotoButtonTemplate(this.#buttonState);
  }

  setSelectPhotoButtonClickHandler = (callback) => {
    this._callback.selectPhotoButtonClick = callback;
    this.element.addEventListener('click', this.#selectPhotoButtonClickHandler);
  };

  #selectPhotoButtonClickHandler = () => {
    this._callback.selectPhotoButtonClick();
  };

  changeButtonState(currentButtonState) {
    if (currentButtonState === MenuButtonState.OPENED) {
      this.element.classList.add('menu-button--opened');
    } else {
      this.element.classList.remove('menu-button--opened');
    }
  }
}
